import { List } from 'antd';
import { Container, Card, Button } from "react-bootstrap";

import '../../assets/css/style.css';

const CategoryList = (props) => {
    return(
        <div className="categories">
            <Container fluid className="text-center align-items-center text" style={{marginLeft: 'auto', width: '100%'}}>
                <List grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                        }}
                dataSource={props.data}
                renderItem={item => (
                    <List.Item>
                        <Card border='light'>
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                            <Button href={item.url} variant="primary">{item.buttonText}</Button>
                            </Card.Body>
                        </Card>
                    </List.Item>
                )} />
                </Container>
        </div>
    )
}

export default CategoryList;
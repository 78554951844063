import './notfound.css';
import Error404 from '../../assets/images/error_page.png';

const PageNotFound = () => {
    return (
        <div className="Error404 TextAlign">
            <img
                style={{ width: '100%' }} src={Error404}
                alt="Testbuzz page not found" />
        </div>
    )
}

export default PageNotFound
import { Component } from "react";
import { Card } from 'antd'
import storage from '../../Firebase';
import './style.module.scss';

const { Meta } = Card;

export default  class ProductCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            productImage: props.item.prodImage || "",
            prodImageUrl: undefined,
            productName : props.item.name || "",
            productNote: props.item.description || ''
        };
    }

    componentDidMount(){
        if(this.state.prodImageUrl === undefined) {
            storage.ref(`${this.state.productImage}`).getDownloadURL().then( url => this.setState({prodImageUrl:url }));
        }
    }

    render(){
        const { prodImageUrl, productName, productNote } = this.state;
        return(
            <Card
                hoverable
                title={productName}
                cover={<img alt={productName} src={prodImageUrl} />}
            >
                <Meta description={productNote} />
            </Card>
        )
    }
};
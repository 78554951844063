import { Component } from "react";
import { List } from "antd";
import ProductCard from "../Card";
import './products.scss';


class ProductsList extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: props.data
        }
    }
    

    render(){
        const { data } = this.state;
        return(
            <div className="card">
                <div className='card-header'>
                    <div className='utils__title'>
                        <strong> Products List</strong>
                    </div>
                </div>
                <div className="card-body">
                    <div className="productsCatalog">
                        <List
                            grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 3,
                            xxl: 3,
                            }}
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <ProductCard item={item}/>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>        
        )
    }

}
/*
const ProductsList = (props) => {
    const [data, setData] = useState(props.data);
    const [loading, setLoading] = useState(false);
    const [hasMore, sethasMore] = useState(false);
    const [ images, setImages] = useState({});

    useEffect( () => {
        data.map(d => {
            if(d.prodImage !== undefined){
                console.log("images in state: ", images[d.name]);
                storage.ref(`${d.prodImage}`).getDownloadURL()
                    .then( url =>{
                        images[d.name] = url;
                        console.log("setting images in state");
                        setImages(images)
                    })
                    .catch(err =>  console.error("errr in image url: ", err));
            }
            return []
        });
    }, [data, images]);

    
    
}

*/
export default ProductsList;
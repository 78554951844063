const data = {
    "categories": [
        {
            title: "Cleaning and Maintenance",
            url: "/cleaning",
            buttonText: "Show SubCategories" ,
        },
        {
            title: "Water Treatment",
            url: "/water",
            buttonText: "Show SubCategories"},
        {
            title: "Fuel Oil & Exhaust Gas Treatment",
            url: "/fueloilgas",
            buttonText: "Show SubCategories" 
        },
        {
            title: "Tank Cleaners",
            url: "/tank",
            buttonText: "Show Products" 
        },
        {
            title: "Cargo Hold Cleaners",
            url: "/cargo",
            buttonText: "Show Products" 
        },
        {
            title: "Floor Care",
            url: "/floor",
            buttonText: "Show Products" 
        },
        {
            title: "House Keeping",
            url: "/house",
            buttonText: "Show Products" 
        },
        {
            title: "Laundary",
            url: "/laundary",
            buttonText: "Show Products" 
        },
        {
            title: "Kitchen Care",
            url: "/kitchen",
            buttonText: "Show Products" 
        },
        {
            title: "Marine Ballast Water Treatment",
            url: "/balastwater",
            buttonText: "Show Products" 
        },
        {
            title: "TEEPOL",
            url: "/teepol",
            buttonText: "Show Product" 
        },
    ],
    "cleaning_maintenance": {
        title: "Cleaning and Maintenance",
        subcategory: [
            { 
                title: "Carbon Removers",
                url: "/carbon",
                buttonText: "Show Products"
                
            },
            { 
                title: "Degreasers and General Cleaners",
                url: "/degreasers",
                buttonText: "Show Products"
            },
        ]
    },
    "water_treatment": {
        title: "Water Treatment",
        subcategory: [
            { 
                title: "Boiling Water Treatment",
                url: "/boiling",
                buttonText: "Show Products"
            },
            { 
                title: "Cooling Water Treatment",
                url: "/cooling",
                buttonText: "Show Products"
            },
            { 
                title: "Potable Water Treatment",
                url: "/potable",
                buttonText: "Show Products"
            },
            { 
                title: "Sewage Treatment",
                url: "/sewage",
                buttonText: "Show Products"
            },
        ],
    },
    "fuel_oil_gas_treatment": {
        title: "Fuel Oil and Exhaust Gas Treatment",
        subcategory: [
            { 
                title: "Fire Side Treatment",
                url: "/fireside",
                buttonText: "Show Products"
            },
            { 
                title: "Gas Side Treatment",
                url: "/gasside",
                buttonText: "Show Products"
            },
            { 
                title: "Fuel Oil Treatment",
                url: "/fueloil",
                buttonText: "Show Products"
            }
        ]
    },
    "carbon_removers": {
        products: [
            {
                name: "Air Cooler Cleaner",
                description: `Is a powerful solvent emulsion cleaner for the cleaning of 
                    diesel engine air coolers, scavenging air systems and compressor sides
                    of turbochargers.`,
                prodImage: 'AIR-COOLER-CLEANER.png',
                key: 'AIR-COOLER-CLEANER'
            },
            {
                name: "Carbon Remover",
                description: `Is a non-toxic powerful solvent for carbonaceous
                    deposits removal. It is a hydrocarbon solvent blend, which does not contain any
                    hazardous cresylic acid or other chlorinated solvents.`,
                prodImage: 'CARBON-REMOVER.png'
            },
            {
                name: "Filter Cleaner",
                description: `Is mainly used for the cleaning of metal/ceramic oil filters,
                    from carbonized oil depositions. A specialized product, containing anionic
                    surface active agents to remove oil depositions, carbonized oil and sludge
                    from metal and ceramic oil filters.`,
                prodImage: 'FILTER-CLEANER.png'
            },
        ]
    },
    "degreasers": {
        products: [
            {
                name: "Solvent Based Oil and Grease Remover",
                description: `Most suitable for removal
                    of oil and grease from machinery parts, tank tops, bulkheads, decks and floor plates and
                    especially for Tank cleaning. DG-01 is also used for cleaning of cargo tanks, bunker tanks and
                    for spot cleaning.`,
                prodImage: 'SOLVENT-BASED-OIL-AND-GREASE-REMOVER.png'
            },
            {
                name: "Solvent Based Bilge cleaner",
                description: `Is a solvent based heavy-duty bilge,
                    engine room and cargo tank cleaner, specially formulated with self splitting
                    action. It allows the slop water to break into separate oil and water phases.`,
                prodImage: 'SOLVENT-BASED-BILGE-CLEANER.png'
            },
            {
                name: "Heavy Duty Water Based Degreaser",
                description: `is a water-based general
                    purpose degreaser with an excellent cleaning efficiency. It effectively removes
                    grease, wax, vegetable and animal fat, oil, sludge, soot, carbon deposits, traffic
                    film, dirt and grime.`,
                prodImage: 'HEAVY-DUTY-WATER-BASED-DEGREASER.png'
            },
            {
                name: "Multipurpose Liquid Detergent",
                description: `Is a superior liquid detergent with good foaming properties.
                    The active wetting agents give rapid penetration to
                    remove light oil and dirt from all surfaces.`,
                prodImage: 'MULTIPURPOSE-LIQUID-DETERGENT.png'
            },
            {
                name: "Environmental Cleaner Degreaser",
                description: `Is a micro-emulsion water based cleaner/degreaser. This product is low toxic, biodegradable and safe to use. It is
                    based on natural citrus oil solvent linked with an advanced surfactant
                    formulation.`,
                prodImage: 'ENVIRONMENTAL-CLEANER-DEGREASER.png'
            },
            {
                name: "Water-Based, Low Toxic Oil Spill Dispersant",
                description: `Is a biodegradable, water-based oil spill dispersant which is highly efficient for use on a wide range
                    of oils. It has extremely low toxicity, is totally biodegradable and contains no
                    petroleum solvents.`,
                prodImage: 'WATER-BASED-LOW-TOXIC-OIL-SPILL-DISPERSANT.png'
            },
            {
                name: "Solvent-Based, Low Toxic Oil Spill Dispersant",
                description: `Is a biodegradable hydrocarbon based product with high dispersing efficiency and low toxicity. Is
                    used to disperse and clear accidental oil spills on deck and in the sea.`,
                prodImage: 'SOLVENT-BASED-LOW-TOXIC-OIL-SPILL-DISPERSANT.png'
            },
            {
                name: "Slow Drying Cleaner &amp; Degreaser for Electrical Parts",
                description: `Is a very effective product, to remove grease, oil and carbon deposits from electric parts
                    and equipment without damaging them. This product has a controlled
                    evaporation rate.`,
                prodImage: 'SLOW-DRYING-AND-CLEANER-DEGREASER-FOR-ELECTRICAL-PARTS.png'
            },
            {
                name: "Fast Dry Electrical Cleaner",
                description: `Is a solvent that effectively and quickly
                    dissolves grease, tar, wax and oil from electrical equipment on contact. It dries in
                    seconds and leaves no residue.`,
                prodImage: 'FAST-DRY-ELECTRICAL-CLEANER.png'
            },
            {
                name: "Environmental Non-CFC&#39;s Containing Electro cleaner:",
                description: `Is a biodegradable, low-toxic product that dissolves oil, grease, wax 
                    and other soils from electrical parts. It has a controlled evaporation rate and
                    leaves a clean surface. It is a low-toxic, nonflammable product, safe to use on 
                    all surfaces.`,
                prodImage: 'ENVIRONMENTAL-NON-CFC&#39_S-CONTAINING-ELECTRO CLEANER.png'
            },
            {
                name: "Heavy Duty Liquid Acid Descaler",
                description: `Is an easy to use acid
                    compound for removing water scale and iron dioxide deposits. It is inhibited to
                    protect metal surfaces. This product is suitable for removal of calcium carbonate
                    and magnesium salts as well as ferrous oxides from metal surfaces.`,
                prodImage: 'HEAVY-DUTY-LIQUID-ACID-DESCALER.png'
            },
            {
                name: "Cement Remover",
                description: `Is used for removing cement residues from all common
                    metal surfaces. It must not be used on zinc, aluminum, galvanized materials and
                    stainless steel.`,
                prodImage: 'CEMENT-REMOVER.png'
            },
            {
                name: "Rust Stain Remover",
                description: `Is used to remove stains from painted surfaces, steel,
                    glass, porcelain, and terrazzo, ceramic tiles, marble and wood. It is suitable for removing
                    rust stains from all types of ferrous metals and leaves metal surfaces passivated so they
                    will resist atmospheric corrosion`,
                prodImage: 'RUST-STAIN-REMOVER.png'
            },
            {
                name: "Separator Disc Cleaner",
                description: `Used for cleaning all types of separators and
                    separator discs. It is suitable for cleaning of disassembled units parts.`,
                prodImage: 'SEPARATOR-DISC-CLEANER.png' 
            }
        ]
    },
    "boiler_water_treatment": {
        products: [
            {
                name: "Boiler Water Treatment",
                description: `A liquid blend of alkaline mineral
                    salts, scale and corrosion inhibitors, oxygen scavenger
                    and sludge conditioner.`,
                prodImage: 'BOILER-WATER-TREATMENT.png'
            },
            {
                name: "Alkalinity Control",
                description: `An excellent, strong alkaline boiler
                    water treatment developed for use in the control of feed
                    water pH, neutralizing any acid present and contributing
                    to alkalinity levels. Also contains sludge conditioning and
                    scale preventing polymers specially selected for their
                    suitability for high temperature boiler applications.`,
                prodImage: 'ALKALINITY-CONTROL.png'
            },
            {
                name: "Condensates Control",
                description: `Is a liquid blend of neutralizing
                    amines applied for the protection of steam and
                    condensate pipe work.`,
                prodImage: 'CONDENSATES-CONTROL.png'
            },
            {
                name: "Hardness Control",
                description: `Is a dry powder product used for
                    hardness reduction and phosphate control in boiling
                    water systems.`,
                prodImage: 'HARDNESS-CONTROL.png'
            },
            {
                name: "Oxygen Control:",
                description: `Is a liquid sodium sulphite product used
                    for oxygen scavenging in low and medium pressure
                    boilers.`,
                prodImage: 'OXYGEN-CONTROL.png'
            },
            {
                name: "Boiler Coagulant",
                description: `Is a liquid sludge conditioner designed
                    to prevent the formation of solid and sticky deposits in
                    boilers`,
                prodImage: 'BOILER-COAGULANT.png'
            },
        ]
    },
    "cooling_water_treatment": {
        products: [
            {
                name: "Nitrite Base Anticorrosion For Closed Circuit Cooling Water Systems",
                description: `Is a nitrite and organics based corrosion inhibitor
                    suitable for all types of engines and other closed re- circulating water
                    systems.`,
                prodImage: 'NITRITE-BASE-ANTICORROSION.png'
            },
            {
                name: `Diesel Cooling Water Corrosion Inhibitor (Safe for Ferrous Metals, Copper Alloys
                    and Aluminum)`,
                description: `Engine Water Treatment contains a balanced mixture of corrosion 
                    inhibitors in liquid form, together with an antiscalant. IS formulated to protect
                    systems containing aluminum components.`,
                prodImage: 'DIESEL-COOLING-WATER-CORROSION-INHIBITOR.png'
            },
            {
                name: `Antifreeze`,
                description: `Is an Ethylene Glycol based anti-freeze, which contains
                    inhibitors to protect radiators and engines, include aluminum engines,
                    against rust and corrosion.`,
                prodImage: 'ANTIFREEZE.png'
            },
            {
                name: `Liquid Evaporator Treatment for Evaporators`,
                description: `Is a liquid based on a low molecular weight poly acrylate which inhibits 
                the formation of carbonate and sulphate scale deposits. A Polymer- based additive to be 
                used in Evaporators to prevent Scale Formation.`,
                prodImage: 'LIQUID-EVAPORATOR-TREATMENT-FOR-EVAPORATORS.png'
            },
            {
                name: `Antifoulant and Corrosion Inhibitor for Sea Water
                Cooling Systems`,
                description: `It gives complete protection against corrosion and
                    fouling by marine growth such as algae, moss, shellfish and micro-organisms
                    in seawater cooling systems. It is also used as an effective biocide to treat
                    diesel engine cooling water systems against micro-organisms and bacteria.`,
                prodImage: 'ANTIFOULANT AND-CORROSION-INHIBITOR-FOR-SEA-WATER-COOLING-SYSTEM.png'
            },
        ]
    },
    "potable_water_treatment": {
        products: [
            {
                name: "Liquid Chlorine (12% Sodium Hypochlorite)",
                description: `Sodium Hypochlorite is
                    universally recognized as a disinfectant and its use is allowed in all civil water
                    supply lines. It is a general biocide substance which kills germs, microorganisms,
                    algae and bacteria and can be used a general disinfectant.`,
                prodImage: ''
            },
            {
                name: `Chlorine Tablets for Potable Water:`,
                description: `An active ingredient of 70% calcium hypochlorite;
                    when dissolved in water, release active chlorine in hypochlorous acid form and are
                    used as oxidizing disinfectants for the conditioning by chlorination of sewage and
                    waste water systems. Used as a disinfectant of supply water and treatment of sewage
                    systems. .`,
                prodImage: ''
            },
            {
                name: `Potable Water Stabilizer:`,
                description: `A liquid combination of polyphosphates, having stabilizing
                    properties. The product effectively and economically provides scale and corrosion
                    control in potable water and other shipboard once-through water systems.`,
                prodImage: ''
            }
        ]
    },
    "sewage_treatment": {
        products: [
            {
                name: "Biological Treatment of Waste Waters in Marine Septic Tanks",
                description: `Encourages the aerobic degradation of wastes in septic tanks.
                    Therefore stops foul odors. Provides biological degradation and
                    liquefying of solid deposits. Treats the wastewater. Acts as a start-
                    up and maintenance product for waste water treatment plants.
                    Increases the efficiency of the septic tank, prevents blockings and
                    need for waste collection.`,
                prodImage: ""
            },
            {
                name: `Liquid Biological Treatment and Cleaner of Marine Sanitary:`,
                description: `Stops foul odors generated by anaerobic microorganisms. Decomposes, 
                    liquefies and biologically degrades the organic deposits. Eases the 
                    cleaning of toilets.`,
                prodImage: ""
            },
            {
                name: `Chlorine Tablets for Sewage`,
                description: `an active ingredient of 70% calcium
                    hypochlorite; when dissolved in water, release active chlorine in
                    hypochlorous acid form and are used as oxidizing disinfectants
                    for the conditioning by chlorination of sewage and waste water
                    systems. Used as a disinfectant of supply water and treatment of
                    sewage systems.`,
                prodImage: ""
            },
            {
                name: `Disinfectant Cleaner for Sewage System:`,
                description: `A strong disinfectant and
                    odor remover. That is used for cleaning of waste water tanks,
                    lavatories and toilets.`,
                prodImage: ""
            }
        ]
    },
    "fire_side_treatment": {
        products: [
            {
                name: "Soot Remover Liquid",
                description: `Is formulated for effective prevention of soot
                and fire scale deposits from boilers and diesel engine exhaust
                systems.`,
                prodImage: "SOOT-REMOVER-LIQUID.png"
            },
            {
                name: "Soot Remover Powder",
                description: `A compound of combustion chemicals,
                    specially formulated to safely remove soot and fireside deposits from
                    boilers and diesel engine exhaust systems.`,
                prodImage: "SOOT-REMOVER-POWDER.png"
            }
        ]
    },
    "fuel_side_treatment": {
        products: [
            {
                name: `Fuel Conditioner and Stabilizer`,
                description: `Avoids sludge formation in tanks, ensuring a clean fuel system. 
                    It also prevents corrosion in tanks and components, by neutralizing fuel acids.`,
                prodImage: "FUEL-CONDITIONER-AND-STABILIZER.png"
            },
            {
                name: `Combustion Improver and Carbon Catalyst`,
                description: `Improves combustion in boilers and diesel engines, while minimizing 
                    carbon build-up. It also avoids acid corrosion, helping save costs.`,
                prodImage: "COMBUSTION-IMPROVER-AND-CARBON-CATALYST.png"
            },
            {
                name: `Vanadium Conditioner`,
                description: `A Post Combustion fuel ash modifier
                    very efficiently reducing high temperature corrosion causing premature
                    exhaust valve failure in low-speed and particularly medium-speed diesels.`,
                prodImage: "VANADIUM-CONDITIONER.png"
            },
            {
                name: `Fuel Oil Biological Bacteria Control:`,
                description: `A specially designed liquid
                    additive, which provides control against microorganisms and bacteria that
                    contaminate fuel storage tanks and systems.`,
                prodImage: "FUEL-OIL-BIOLOGICAL-BACTERIA-CONTROL.png"
            }
        ]
    },
    "gas_side_treatment": {
        products: [
            {
                name: `Asteroids:`,
                description: `Asteroids are organic materials with controlled particle size
                    used for cleaning blades of gas side of exhaust - gas turbochargers in modern
                    diesel engines.`,
                prodImage: "ASTEROIDS.png"
            }
        ]
    },
    "cargo_hold": {
        products: [
            {
                name: `Heavy Duty Alkaline Cleaner High Foam`,
                description: `Is a heavy duty alkaline
                    cleaner with good foaming qualities specially formulated for cleaning cargo holds
                    on bulk carriers and OBOs. CH-01 is highly concentrated, comprising special
                    detergents, surfactants and corrosion inhibitors`,
                prodImage: "HEAVY-DUTY-ALKALINE-CLEANER-HIGH-FOAM.jpg"
            },
            {
                name: `Rust Remover for Cargo Hold Surfaces`,
                description: `Liquid detergent compound
                    containing phosphoric acid and nonionic surfactants, used for rust removal and
                    as a surface brightener.`,
                prodImage: "RUST-REMOVER-FOR-CARGO-HOLD-SURFACES.jpg"
            },
            {
                name: `Hold Surface Protector`,
                description: `Is a water based liquid product specially
                    formulated to leave a thin, temporary film on cargo hold surfaces. The film
                    provides a barrier between the cargo and the cargo hold surfaces making the
                    cleaning operation after unloading quicker and easier, and optimal cleaning
                    result is achieved.`,
                prodImage: "HOLD-SURFACE-PROTECTOR.jpg"
            }
        ]
    },
    "tank_cleaners": {
        products: [
            {
                name: `Heavy Duty Alkaline Tank Cleaner`,
                description: `Strong alkaline liquid degreasing
                    agent for the removal of vegetable, fish and animal oils and fats from epoxy /
                    polyurethane coated or stainless steel tanks by injection or circulation method.`,
                prodImage: "HEAVY-DUTY-ALKALINE-TANK-CLEANER.jpg"
            },
            {
                name: `Non-Caustic Alkaline Tank Cleaner`,
                description: `Is a powerful non caustic alkaline
                    cleaner formulated for cleaning vegetable and animal oils, fatty acid and greases,
                    whether or not hardened or carbonized. The product is safe to use on epoxy-
                    coatings. It is suited to use on most metals except for aluminum, tin, zinc and
                    galvanized material.`,
                prodImage: "NON-CAUSTIC-ALKALINE-TANK-CLEANER.jpg"
            },
            {
                name: `Non-Toxic, Water Based Safety Alkaline Cleaner:`,
                description: `is a non-toxic,
                    water-based alkaline cleaner. It contains fast penetrating and wetting agents to
                    remove soils and corrosion inhibitors to prevent corrosion on zinc, aluminum
                    copper, tin or brass.`,
                prodImage: "NON-TOXIC-WATER-BASED-SAFETY-ALKALINE-CLEANER.jpg"
            },
            {
                name: `Heavy Duty Concentrated Tank cleaner`,
                description: `Biodegradable solvent based
                    tank cleaner with strong emulsifying action and low odor Has a wide range of
                    applications. It is suitable for cleaning double bottom, deep tanks, cargo tanks and bilges or
                    generally fuel oil storage tanks. It may also be used for engine room and/or deck cleaning and
                    degreasing.`,
                prodImage: "HEAVY-DUTY-CONCENTRATED-TANK-CLEANER.jpg"
            },
            {
                name: `Solvent Based Tank cleaner`,
                description: `Is a specially formulated chlorinated
                    hydrocarbons-free tank cleaner and degreaser, based on low odor solvents,
                    emulsifiers and surfactants. Suitable for cleaning Cargo Tanks, Bilge Tanks, Holds,
                    Pump &amp; Engine Rooms. It can also be used in oil coolers, fuel oil pre-heaters and heat
                    exchangers.`,
                prodImage: "SOLVENT-BASED-TANK-CLEANER.jpg"
            },
            {
                name: `Heavy Duty Water Based Hydrocarbon Free Tank Cleaner`,
                description: `Can be used for the removal of vegetable and animal oils, fats, and 
                    light mineral oils in aluminum and zinc silicate coated tanks. Is formulated with 
                    neutral pH Grease cutting nonionic surfactants to efficiently dissolve and clean 
                    organic soils from delicate tank surfaces.`,
                prodImage: "HEAVY-DUTY-WATER-BASED-HYDROCARBON.jpg"
            },
            {
                name: `Water Based Neutral Tank Cleaning Detergent:`,
                description: `pH-neutral, liquid
                    concentrated detergent with good cleaning qualities. Efficiently cleans surfaces
                    from animal oil, vegetable oil, fish oil, fat and waxes.`,
                prodImage: "WATER-BASED-NEUTRAL-TANK-CLEANING-DETERGENT.jpg"
            },
            {
                name: `Stainless Steel Passivating Liquid`,
                description: `Is only suitable for use on austenitic
                    stainless steel. The product is mainly used for passivating stainless steel
                    installations and tanks.`,
                prodImage: "STAINLESS-STEEL-PASSIVATING-LIQUID.jpg"
            },
            {
                name: `Stainless Steel Pickling Liquid:`,
                description: `Is based on nitric acid, hydrofluoric acid
                and non-ionic surface-active substances. The fluid may be used only on austenitic
                stainless steel as it corrodes other materials. It removes weld slag, annealing
                scale, grinding dust and iron parts.`,
                prodImage: "STAINLESS-STEEL-PICKLING-LIQUID.jpg"
            },
            {
                name: `Rust and Stain Remover`,
                description: `A liquid, acid based rust remover and surface brightener
                    On some coatings, washing with hot sea water after discharge of caustic or different lye
                    cargoes, may result in white stains or stripes on tank surfaces. Can be used to remove these
                    white stains/stripes.`,
                prodImage: "RUST-AND-STAIN-REMOVER.jpg"
            },
            {
                name: `Dye Remover from Tank Coatings:`,
                description: `A specially formulated water based
                alkaline tank cleaner. TC-11 is bio-degradable and free from hydro- carbon
                solvents and estrogenic compounds. Safe to be used on epoxy coatings. It
                removes traces of discoloration, before loading sensitive cargoes.`,
                prodImage: "DYE-REMOVER-FROM-TANK-COATINGS.jpg"
            }
        ]
    },
    "floor_care": {
        products: [
            {
                name: `LIQUID MARBLE CRYSTALIZER :`,
                description: ` Highly Recommended Product for Marble, Limestone, Italian Marble.
                 Excellent Mirror Shine.
                 Long Lasting Shine.
                 Good Slip Resistance.
                 Scratch Free.
                 Restores the Surface to its Original Appearance &amp; Colour`,
                prodImage: ""
            },
            {
                name: `FLOOR POLISH STRIPPER`,
                description: `Is a universal stripper for removing polish from
                water resistant hard floor. Is a alkaline stripper which effectively penetrates maintenance and
                polish layers and is low forming. Quickly removes polish and can be applied on all water
                resistant floors.`,
                prodImage: ""
            },
            {
                name: `FLOOR POLISH AND SEALER`,
                description: `Is a dry bright floor polish and sealer which
                when applied forms a protective transparent film on the surface protecting the floor from
                wear and tear due to continues abrasive action. Is a durable polish especially when you need
                it most under traffic area. Can be applied on all types of hard floors, e.g. vinyl, linoleum,
                artificial and natural stone floor.`,
                prodImage: ""
            }

        ]
    },
    "house_keeping": {
        products: [
            {
                name: `BATHROOM CLEANER CUM SANITIZER CONCENTRATE`,
                description: `Is a ready formulation for cleaning and sanitizing surfaces in the bathroom, sinks, floor and tiles . It
                has a pleasant fragrance and is free from bleaches. It can be used on hard floors and granite to
                removes soap scum and hard water stains from taps, tiles, shower and toilet bowl.`,
                prodImage: "bathroom_cleaner_cum_sanitizer_concentrate.png"
            },
            {
                name: `HARD SURFACE CLEANER CONCENTRATE`,
                description: `Is a concentrated cleaner
                containing non-ionic surfactants for cleaning marble, marbonite tiles, granite, vinyl flooring
                and other hard surfaces. This product is pleasantly scented and is user friendly.`,
                prodImage: "hard_surface_cleaner_concentrate.png"
            },
            {
                name: `GLASS CLEANER CONCENTRATE`,
                description: `Is a glass cleaner concentrate solution
                for cleaning all types of glass, mirrors, vinyl, sun mica, rexine and plastic laminates. Regular
                use will retain the surface clean and shiny. It helps to cut grease and leaves no streaks.`,
                prodImage: "glass_cleaner_concentrate.png"
            },
            {
                name: `FURNITURE MAINTAINER`,
                description: `Is a tailor made formulation for maintenance of
                wooden furniture. It helps to retain polished surfaces and makes the furniture look bright and
                shiny.`,
                prodImage: "furniture_maintainer.png"
            }
            ,
            {
                name: `LIQUID ROOM FRESHENER`,
                description: `Is a ready to use product and has a pleasant
                fragrance it deodorizes guest rooms, banquet halls, homes, offices rooms and theatres.`,
                prodImage: ""
            }
            ,
            {
                name: `TOILET BOWL CLEANER`,
                description: `Is a ready to use liquid, which is useful to clean
                Indian and Western toilet bowls. It is useful for porcelain and ceramic coated surfaces. It can
                be used to clean bathroom wall tiles and ceramic floor tiles.`,
                prodImage: "TOILET_BOWL_CLEANER.png"
            }
            ,
            {
                name: `FLOOR CLEANER CONCENTRATE:`,
                description: `Is a fragrant and effective cleaner for
                all types of surfaces. It can be used for wet mopping as well as scrubbing with the machine. It
                can be also used to wash banquet halls.`,
                prodImage: "floor_cleaner_concentrate.png"
            }
            ,
            {
                name: `HEAVY DUTY INDUSTRIAL DEGREASER`,
                description: `is a highly concentrated floor
                cleaner and degreaser containing a blend of surfactants and non-caustic alkalis to remove
                grease, oil and other types of stubborn grime from a variety of surfaces. Safe to use on any
                hard surfaces. Leaves surfaces clean with a pleasant fragrance.`,
                prodImage: ""
            },
            {
                name: `STAINLESS STEEL POLISH`,
                description: `Is a tailor made polish for maintenance of
                stainless steel. It protects against grease and grime and leaves the surface with a bright
                finish.`,
                prodImage: "STAINLESS_STEEL_POLISH.png"
            },
            {
                name: `CARPET SHAMPOO`,
                description: `Is a high foaming condensed lather-cleaning action for
                effective soil penetration. It has excellent detergency and anti-static properties. Loosens and
                lifts out embedded dirt, oil, grease and stains. Leaves no gummy residue, and makes the
                carpet soft. It can be used in foam generator machines. Safe for fibers.`,
                prodImage: ""
            },
            {
                name: `CARPET DETERGENT`,
                description: `Carpet Detergent is a concentrated liquid detergent for
                removing stains from carpets and upholstery. It has excellent detergency and can be used in
                offices, homes on car seat covers, car roof to remove stains. loosens and lifts embedded dirt,
                oil grease and stains leaves, no gummy residue, and makes the carpet look soft and clean.`,
                prodImage: ""
            },
            {
                name: `LIQUID HAND CLEANSER`,
                description: `Soft gentle cleanser is super concentrated
                cleansing lotion specially formulated to clean, help soften, hands and moisturize sensitive
                skin. Is perfect for general hand washing needs. pH balanced to protect skin.`,
                prodImage: ""
            },
            {
                name: `MULTI SURFACE CREME CLEANSER`,
                description: `Is a tailor made multi surface crème cleanser that removes hard water, soap scum, mildew stains
                from taps, tiles, showers, steel fixtures, bath tub and other areas without scratching. It cleans vertical
                surfaces and rinses freely leaving the surface clean bright and shiny. It has a mild acidic formula and
                has a pleasant fragrance. It can be used on porcelain, ceramic, chrome, stainless steel, copper
                bottom vessels, formica in wash room, kitchen and other areas.`,
                prodImage: ""
            },
            {
                name: `DOUBLE STRENTH FLOOR CLEANER`,
                description: `Is a double strength floor
                cleaner with QAC which is safe on all types of floors. Effectively removes stubborn stains
                and has a pleasing fragrance. Effective in all water conditions.`,
                prodImage: ""
            }
        ]
    },
    "laundary": {
        products: [
            {
                name: `ADVANCED ACTION DETERGENT`,
                description: `Is a advanced action detergent free
                flowing powder and nonionic in nature. Is completely soluble in water and is used for washing and
                cleaning of F &amp; B linen, hospital linen, engineering and workshop overalls.`,
                prodImage: ""
            },
            {
                name: `BUILDER BOOSTER`,
                description: `an odourless free flowing powder alkaline in nature
                formulated to remove heavy soils from F&amp;B linen, workshop and engineering overalls. It can
                be used in conjunction with CLAP 100 to give an enhanced performance. Is safe on fabrics.`,
                prodImage: ""
            },
            {
                name: `LIQUID EMULSIFIER CONCENTRATE `,
                description: `Is concentrated non ionic liquid detergent for easy removal of mineral oils, and greasy stains from chef's uniforms, mechanics and engineering overalls.`,
                prodImage: ""
            },
            {
                name: `SOURING AGENT LIQUID NEUTRALISER `,
                description: `Is a colorless water white liquid, odorless, and easily soluble in water. Neutralizes excess of alkali in the laundry bath towards the end of the final wash process.`,
                prodImage: ""
            },
            {
                name: `ALKALI AND BLEACH NEUTRALIZER`,
                description: `Is a colourless water white liquid also known as neutralizer, which helps to neutralize alkali as well as chlorine in the wash process.`,
                prodImage: ""
            },
            {
                name: `FABRIC SOFTENER `,
                description: `Is an amphoteric based fabric softener recommended for commercial and in house laundry users, for hosiery and terry towels, wool, making linen soft, fluffier and fragrant.`,
                prodImage: ""
            },
        ]
    },
    "kitchen_care": {
        products: [
            {
                name: `DRAIN CLEANER AND MAINTAINER`,
                description: `Is a concentrated formulation designed to release the grease from traps, drains in restaurants and hotel industry. It is very effective in grease removal. Is effective in blocking the production of odour occurring through natural bacterial populations.`,
                prodImage: ""
            },
            {
                name: `LIQUID HAND CLEANSER`,
                description: `Is a hand cleanser containing Alkyl dimethyl benzyl ammonium chloride having bactericidal properties useful in food and pharma processing segment. It is a product which does not contain perfume. Contains quaternary ammonium compound which is an effective and powerful antibacterial.`,
                prodImage: ""
            },
            {
                name: `DISHWASHING CONCENTRATE`,
                description: `Is a manual liquid detergent concentrate to remove grease and grime from pot and pan, glassware, cutlery, crockery, mixers, blenders in hotels and institutions.`,
                prodImage: ""
            },
            {
                name: `ALL PURPOSE LIQUID DETERGENT `,
                description: `Is a manual liquid detergent concentrate removes grease and grime from pot and pan, glassware, cutlery, crockery, mixers, blenders in hotels and institutions.`,
                prodImage: ""
            },
            {
                name: `DESCALER LIQUID CONCENTRATE`,
                description: `Is a highly acidic descaler concentrate containing organic acids for the easy removal of lime scale from kitchen equipment, bathroom taps, utensils, etc.`,
                prodImage: ""
            },
            {
                name: `COPPER AND BRASS CLEANER `,
                description: `Is an effective tailor made formulation for cleaning and shining copper and brass equipment in hotels, institutions and homes`,
                prodImage: ""
            },
            {
                name: `UTENSIL LIQUID CONCENTRATE`,
                description: `Utensil Liquid Concentrate is a tailor made formula for general cleaning and degreasing of utensils, glassware, crockery, cutlery in restaurants, hotels, canteens, homes etc. Safe on hands and has a pleasing Lemon fragrance.`,
                prodImage: ""
            },
        ]
    },
    'marine_balast_water': {
        products: [
            {
                name: `SODIUM THIOSULPHATES`,
                description: ``,
                prodImage: ""
            },
            {
                name: `PER ACETIC ACID`,
                description: ``,
                prodImage: ""
            },
            {
                name: `CAUSTIC SODA`,
                description: ``,
                prodImage: ""
            },
            {
                name: `ISO PROPYL ALCOHOL`,
                description: ``,
                prodImage: ""
            },
            {
                name: `ACETONE`,
                description: ``,
                prodImage: ""
            }
        ]
    },
    'teepol': {
        products: [
            {
                name: `TEEPOL`,
                description: `Anti Bacterial Disinfectant Detergent, 100% Biodegradable, Kills All Germs`,
                prodImage: "TEEPOL.jpg"
            }
        ]
    }
};

const fetchCategories = (prod) => {
    return data[prod];
}

const fetchSubCategories = (category) => {
    return data[category]["subcategory"]
}

const fetchProducts = (subCategory) => {
    return data[subCategory].products;
}

export {fetchCategories, fetchSubCategories, fetchProducts};
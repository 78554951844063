import './header.css';
import '../../assets/css/style.css'
import { Menu } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
import { Navbar, Nav, Button} from "react-bootstrap";
import mh_marine_logo from "../../assets/images/mh_marine_logo.png";

const { SubMenu } = Menu;

const BasicHeader = (props) => {

    const history = useHistory();
    const onProductsClicked = (key) => {
        history.push(`/${key.key}`);
    }
    
    return(
        <>
            <Navbar bg="dark" expand="lg">
                <Navbar.Brand href="/">
                    <img src={mh_marine_logo} width="50" height="50" className="d-inline-block align-top" alt="MH MARINE SERVICE COMPANY Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="mailto:info@mhmarine.in" className="d-flex align-items-center align-items-stretch">
                            <div className="icon d-flex justify-content-center align-items-center">
                                <MailFilled />
                            </div>
                            <span className="d-flex text align-items-center">info@mhmarine.in</span>
                        </Nav.Link>
                        <Nav.Link href="tel://+919004249383" className="d-flex align-items-center align-items-stretch">
                            <div className="icon d-flex justify-content-center align-items-center">
                                <PhoneFilled />
                            </div>
                            <span className="d-flex text align-items-center">Call Us on: +91 9004249383</span>
                        </Nav.Link> 
                        <Nav.Link className="d-flex align-items-center align-items-stretch">
                            <Button size="lg" onClick={() => history.push('/contacts') } className="primary-btn d-flex justify-content-center align-items-center">
                                Request a Quote
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            
            <Menu style={{ width: '100%', backgroundColor: '#ffffff', color: '#1d2324' }} defaultSelectedKeys={['home']} mode="horizontal">
                <Menu.Item key="home">
                    Home
                    <Link to='/' />
                </Menu.Item>
                <Menu.Item key="about">
                    About Us
                    <Link to='/about' />
                </Menu.Item>
                <SubMenu key="products" title="Our Products" onTitleClick={(key) => onProductsClicked(key)} >
                    <SubMenu key="cleaning" title="Cleaning and Maintenance" onTitleClick={(key) => onProductsClicked(key)}>
                        <Menu.Item key="carbon">
                            Carbon Removers
                            <Link to='/carbon' />
                        </Menu.Item>
                        <Menu.Item key="degreasers">
                            Degreasers and General Cleaners
                            <Link to='/degreasers' />
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="water" title="Water Treatment" onTitleClick={(key) => onProductsClicked(key)}>
                        <Menu.Item key="boiling">
                            Boiling Water Treatment
                            <Link to='/boiling' />
                        </Menu.Item>
                        <Menu.Item key="cooling">
                            Cooling Water Treatment
                            <Link to='/cooling' />
                        </Menu.Item>
                        <Menu.Item key="potable">
                            Potable Water Treatment
                            <Link to='/potable' />
                        </Menu.Item>
                        <Menu.Item key="sewage">
                            Sewage Treatment
                            <Link to='/sewage' />
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="fueloilgas" title="Fuel Oil and Exhaust Gas Treatment" onTitleClick={(key) => onProductsClicked(key)}>
                        <Menu.Item key="fireside">
                            Fire Side Treatment
                            <Link to='/fireside' />
                        </Menu.Item>
                        <Menu.Item key="fueloil">
                            Fuel Oil Treatment
                            <Link to='/fueloil' />
                        </Menu.Item>
                        <Menu.Item key="gasside">
                            Gas Side Treatment
                            <Link to='/gasside' />
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="tank">
                        Tank Cleaners
                        <Link to='/tank' />
                    </Menu.Item>
                    <Menu.Item key="cargo">
                        Cargo Hold Cleaners
                        <Link to='/cargo' />
                    </Menu.Item>
                    <Menu.Item key="floor">
                        Floor Care
                        <Link to='/floor' />
                    </Menu.Item>
                    <Menu.Item key="house">
                        House Keeping
                        <Link to='/house' />
                    </Menu.Item>
                    <Menu.Item key="laundary">
                        Laundary
                        <Link to='/laundary' />
                    </Menu.Item>
                    <Menu.Item key="kitchen">
                        Kitchen Care
                        <Link to='/kitchen' />
                    </Menu.Item>
                    <Menu.Item key="balastwater">
                        Marine Ballast Water Treatment
                        <Link to='/balastwater' />
                    </Menu.Item>
                    <Menu.Item key="teepol">
                        Teepol
                        <Link to='/teepol' />
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="contacts">
                    Contact Us
                    <Link to='/contacts' />
                </Menu.Item>
            </Menu>
        </>
    )
}

export default BasicHeader;

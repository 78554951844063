import { Container, Row, Col } from "react-bootstrap";
// import ProductsList from "../../components/ProductsList";
import CategoryList from "../../components/CategoryList";
import ProductsList from "../../components/ProductsList";
import {fetchCategories, fetchProducts, fetchSubCategories} from "../../Data";

import './category.css'

const CategoriesPage = (props) => {
    return(
        <div className="products_page">
            <div className="category-bg">
                <Container>
                    <Row>
                        <Col>
                            <div className="categorytitle">
                                <h2>{props.title}</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                props.category !== undefined ? (
                    props.category === 'categories'? (
                        <CategoryList data={fetchCategories(props.category)}></CategoryList>
                    ): (
                        <CategoryList data={fetchSubCategories(props.category)}></CategoryList>
                    )
                ): props.subcategory !== undefined ? (
                    <ProductsList data={fetchProducts(props.subcategory)} />
                ): (
                    <ProductsList data={[]} />
                )
                
            }
        </div>
    )
}

export default CategoriesPage;

/*
*/
import { Container, Row, Col, Nav } from "react-bootstrap";

import { HomeOutlined, MailOutlined, PhoneOutlined, GlobalOutlined } from "@ant-design/icons";

import mh_marine_logo from "../../assets/images/mh_marine_logo.png";
import fb_icon from "../../assets/images/fb_icon.png";
import insta_icon from "../../assets/images/insta_icon.jpg";
import tweet_icon from "../../assets/images/tweet_icon.png";

import './footer.css';

const Footer = () => {
    return(
        <footer>
            <Container>
                <Row className="mb-5 text-align-center">
                    <Col md={4}>
                        <div className="mb-3">
                            <h2 className="footer_header footer_company pt-4">
                                <img src={mh_marine_logo} width="40" height="40" className="d-inline-block align-top" alt="MH MARINE SERVICE Company Logo" />
                                <span className="footer_company_name"> MH MARINE </span>
                            </h2>
                            <p>
                                Committed to deliver quality range of Marine Chemicals, All Types of
                                Degreasers, Maintenance & Cleaning Chemicals, Tank Cleaners, Water
                                Treatment, Marine Paints for Epoxy Anti corrosion, Coatings, Test Kits and
                                other products Peracetic Acid 35% and Sodium Thiosulphates 30%, to the 
                                clients and are tested in renowned labs.
                            </p>
                            <ul id="footer_list" className="list-unstyled  md-3">
                                <li>
                                    <Nav.Link href="https://www.facebook.com/mhmarine2" >
                                        <div className="social_icon d-flex justify-content-center align-items-center">
                                            <img src={fb_icon} alt="MHMARINEFB" />
                                        </div>
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link href="https://www.instagram.com/mhmarineservice/" >
                                        <div className="social_icon d-flex justify-content-center align-items-center">
                                            <img src={insta_icon} alt="MH_MARINE_INSTA" />
                                        </div>
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link href="https://twitter.com/mhmarine2" >
                                        <div className="social_icon d-flex justify-content-center align-items-center">
                                            <img src={tweet_icon} alt="MH_MARINE_INSTA" />
                                        </div>
                                    </Nav.Link>
                                </li>
                            </ul>
                            
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="mb-3">
                            <h2 className="footer_header pt-4">
                                Contact Info
                            </h2>
                            <Row>
                                <Col>
                                    <p><span><HomeOutlined /></span> Swapnapurti Bhawan, Plot No. 2A, 
                                        Near Bank of Baroda, Andheri(E),  Mumbai-69(India)
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><span><PhoneOutlined /></span> <a href="tel://9004249383">+91 9004249383</a></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><span><MailOutlined /></span> <a href="mailto:info@mhmarine.in">info@mhmarine.in</a></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><span><GlobalOutlined /></span> <a href="/">www.mhmarine.in</a></p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="mb-3">
                            <h2 className="footer_header pt-4">
                                Important Links
                            </h2>
                            <ul className="list-unstyled">
                                <li>
                                    <Nav.Link href="/" >
                                        Home
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link href="/about" >
                                        About Us
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link href="/products" >
                                        Our Products
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link href="/contacts" >
                                         Contact Us
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="text-center small copyright">
            © MH MARINE SERVICE 2020
            </div>
        </footer>
    )
}

export default Footer;
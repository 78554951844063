import firebase from "firebase/app";
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyB7gzcaDQl1O0gKm-WQR6svvec15E-ICJk",
    authDomain: "mh-marine-service.firebaseapp.com",
    projectId: "mh-marine-service",
    storageBucket: "mh-marine-service.appspot.com",
    messagingSenderId: "983635537802",
    appId: "1:983635537802:web:0821c74cfaf0c669e86273",
    measurementId: "G-Y4YZ2804GK"
};

firebase.initializeApp(firebaseConfig);
// firebaseConfig.analytics();
const storage = firebase.storage()

export default storage;
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import mh_marine_ship from "../../assets/images/about_us.jpg";

import './about.css';

const AboutPage = () => {
    return(
        <Fragment>
            <div className="about-bg">
                <Container>
                    <Row>
                        <Col>
                            <div className="abouttitle">
                                <h2>about Us</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section className="abouts">
                <dir className="abouts-bg">
                    <Container>
                        <Row>
                            <Col md={7} sm={7} lg={7} xl={7}>
                                <div className="abouts-us" >
                                    <h3><strong className="cur">MH MARINE SERVICE</strong></h3>
                                    <p><strong>We MH Marine would like to thank you for visiting
                                        our website</strong>.</p>
                                    <p>
                                        <strong>“MH Marine” </strong> is a well established in the Indian
                                        Marine Industry. Our products are known for
                                        its <strong>Commendable Quality, Competitive
                                        Pricing and Comprehensive
                                        Range.</strong>
                                    </p>
                                    <p>
                                        We at MH Marine study the customer requirements
                                        and deliver products to customer satisfaction. The three
                                        P”s of successful business is <strong>PRICE, PRODUCT and
                                        PACKING</strong>. We are
                                        confident to provide result oriented products and it is
                                        our constant Endeavour to keep our existing, new and
                                        satisfied customers happy.
                                    </p>
                                    <p>
                                        We serves all Indian ports and leading port worldwide
                                        with its wide product range and responds to their
                                        needs with state of the technology. In a global market,
                                        we are one e-mail or phone call away.
                                    </p>
                                </div>
                            </Col>
                            <Col md={5} sm={5} lg={5} xl={5}>
                                <div className="abouts-us">
                                    <figure>
                                        <img height={500} src={mh_marine_ship} alt="MH MARINE SHIP" />
                                    </figure>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </dir>
            </section>
            <section className="value-section">
                <Container>
                    <Row>
                        <Col md={6} lg={6} xl={4}>
                            <div className="heading-value">
                                <h2 className="mb-4">Values</h2>
                            </div>
                            <div className="value-flow">
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Orientation</h3>
                                        <p>To have focus and build long lasting relationships with its
                                            customers</p>
                                    </div>
                                </div>
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Quality</h3>
                                        <p>
                                        To fulfill utmost customer satisfaction by investing in
                                            Quality of our product &amp; service offerings
                                        </p>
                                    </div>
                                </div>
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Trust</h3>
                                        <p>To develop faithful relationships with all its stakeholders</p>
                                    </div>
                                </div>
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Team Work</h3>
                                        <p>To work in a conducive and diverse environment in
                                            achieving our goals</p>
                                    </div>
                                </div>
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Meritocracy</h3>
                                        <p>To ensure excellence in every activity that we intend to be
                                            involved in.</p>
                                    </div>
                                </div>
                                <div className="values-2 p-4 d-flex ">
                                    <div className="text">
                                        <h3>Ethics</h3>
                                        <p>To uphold business ethics across all verticals &amp; deliver on
                                            promises</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={1} className="d-none" />
                        <Col md={6} lg={6} xl={7} >
                            <div className="more-info p-4 d-flex ">
                                <div className="text">
                                    <h3>OUR TEAM</h3>
                                    <p>
                                    Our team of professionals has enabled us to establish a strong
                                    foothold in the competitive market. We have a team of
                                    professionals, who execute the business operations in a
                                    systematic manner.</p>
                                </div>
                            </div>
                            <div className="more-info p-4 d-flex ">
                                <div className="text">
                                    <h3>QUALITY ASSUARANCE</h3>
                                    <p>Since inception, we are committed to deliver quality
                                    range of <strong>Marine Chemicals, All Types of Degreasers,
                                    Maintenance &amp; Cleaning Chemicals, Tank Cleaners,
                                    Water Treatment, Marine Paints for Epoxy Anti
                                    corrosion, Coatings, Test Kits</strong> and other products
                                    <strong>Peracetic Acid 35%</strong> and <strong>Sodium Thiosulphates 30%</strong>,
                                    to the clients and are tested in renowned labs</p>
                                </div>
                            </div>
                            <div className="more-info p-4 d-flex ">
                                <div className="text">
                                    <h3>CLIENT SATISFACTION</h3>
                                    <p>
                                    Whatever the situation, wherever your vessel is located, you need
                                    reliable marine chemical products that work efficiently and
                                    effectively. Our extensive product portfolio, which includes two of
                                    the industry’s most trusted and respected brands, is aligned to
                                    meet all your needs and with global standardization. You can rely
                                    on the same quality, every time, everywhere. All chemical
                                    products are standards, with rigorous environmental management
                                    systems in place throughout the production and delivery process.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div>

            </div>
        </Fragment>
    )
}

export default AboutPage;
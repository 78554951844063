import './App.css';
import { Router,
  Switch,
  Route,
} from 'react-router-dom';

import { createBrowserHistory } from "history";
import Landing from './pages/Landing';
import AboutPage from './pages/About';
import CategoriesPage from './pages/CategoryPage';
import ContactPage from './pages/Contact';
import PageNotFound from './pages/NotFound';
import RequestQuotePage from './pages/RequestQuotePage';
import BasicHeader from "./components/Header";

import Footer from "./components/Footer";

const history = createBrowserHistory();

function App() {
  return (   
    <div className="App">
      <Router onUpdate={ window.scrollTo(0,0) } history={ history } >
            <BasicHeader />
            <Switch>
              <Route exact={true} path="/" component= {Landing } />
                  <Route exact={true} path="/about" component={AboutPage}/>
                  <Route exact={true} path="/products" component={()=><CategoriesPage title="Our Products" category="categories"/>} />
                  <Route exact={true} path="/contacts" component={ContactPage} /> 
                  <Route exact={true} path="/quote" component={RequestQuotePage} /> 
                  <Route exact={true} path="/water" component={()=><CategoriesPage title="Water Treatment" category="water_treatment"/>} />
                  <Route exact={true} path="/boiling" component={()=><CategoriesPage title="Boiling Water Treatment" subcategory="boiler_water_treatment"/>} />    
                  <Route exact={true} path="/cooling" component={()=><CategoriesPage title="Cooling Water Treatment" subcategory="cooling_water_treatment"/>} />
                  <Route exact={true} path="/potable" component={()=><CategoriesPage title="Potable Water Treatment" subcategory="potable_water_treatment"/>} />
                  <Route exact={true} path="/sewage" component={()=><CategoriesPage title="Sewage Treatment" subcategory="sewage_treatment"/>} />
                  <Route exact={true} path="/fueloilgas" component={()=><CategoriesPage title="Fuel Oil and Exhaust Gas Treatment" category="fuel_oil_gas_treatment"/>} />
                  <Route exact={true} path="/fueloil" component={()=><CategoriesPage title="Fuel Oil Treatment" subcategory="fuel_side_treatment"/>} />
                  <Route exact={true} path="/fireside" component={()=><CategoriesPage title="Fire Side Treatment" subcategory="fire_side_treatment"/>} />
                  <Route exact={true} path="/gasside" component={()=><CategoriesPage title="Gas Side Treatment" subcategory="gas_side_treatment"/>} />
                  <Route exact={true} path="/cleaning" component={()=><CategoriesPage title="Cleaning and Maintenance" category="cleaning_maintenance"/>} />
                  <Route exact={true} path="/carbon" component={()=><CategoriesPage title="Carbon Removers" subcategory="carbon_removers"/>} />
                  <Route exact={true} path="/degreasers" component={()=><CategoriesPage title="Degreasers and General Cleaners" subcategory="degreasers"/>} />
                  <Route exact={true} path="/tank" component={()=><CategoriesPage title="Tank Cleaners" subcategory="tank_cleaners"/>} />
                  <Route exact={true} path="/cargo" component={()=><CategoriesPage title="Cargo Hold Cleaners" subcategory="cargo_hold"/>} />
                  <Route exact={true} path="/floor" component={()=><CategoriesPage title="Floor Care" subcategory="floor_care"/>} />
                  <Route exact={true} path="/house" component={()=><CategoriesPage title="House Keeping" subcategory="house_keeping"/>} />
                  <Route exact={true} path="/laundary" component={()=><CategoriesPage title="Laundary" subcategory="laundary"/>} />
                  <Route exact={true} path="/kitchen" component={()=><CategoriesPage title="Kitchen Care" subcategory="kitchen_care"/>} />
                  <Route exact={true} path="/balastwater" component={()=><CategoriesPage title="MArine BaLlast Water" subcategory="marine_balast_water"/>} />
                  <Route exact={true} path="/teepol" component={()=><CategoriesPage title="Teepol" subcategory="teepol"/>} />
                  <Route component={PageNotFound} />
              </Switch>
          </Router>
        <Footer />
    </div>
  );
}

export default App;

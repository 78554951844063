import { Fragment } from "react";
import { Carousel, List } from "antd";
import { Button, Row, Col, Container, Card } from "react-bootstrap";

import {fetchCategories} from "../../Data";
import mh_marine_service from '../../assets/images/mh_marine_service.jpg';
import test_kits from "../../assets/images/test_kits.jpg";
import hotel_house_laundary from "../../assets/images/hotel_house_laundary.jpg";
import paints from "../../assets/images/paints.jpg";
import marine_cargo_tank from "../../assets/images/marine_cargo_tank.jpg";
import marine_balast_water from "../../assets/images/marine_balast_water.jpg";
import industrial_pharmaceutical from "../../assets/images/industrial_pharmaceutical.jpg";


import './landing.css';

const carousel1Style = {
    height: '700px',
    lineHeight: '160px',
    background: `url(${mh_marine_service}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
};

const Frontcategories = [
    {
        title: 'MARINE CHEMAICALS CARGO HOLD TANK CLEANERS',
        img: marine_cargo_tank,
        alt: 'Marine Chemicals, tank cleaners, cargo hold'
    },
    {
        title: 'HOTEL / HOUSE CARE AND LAUNDARY',
        img: hotel_house_laundary,
        alt: 'Hotel/House Care and Laundary'
    },
    {
        title: 'TEEPOL - INDUSTRIAL CARE / PHARMACEUTICAL CARE',
        img: industrial_pharmaceutical,
        alt: 'teepol-industrial and pharmaceutical care'
    },
    {
        title: 'TEST KITS',
        img: test_kits,
        alt: 'Test Kits'
    },
    {
        title: 'MARINE BALAST WATER',
        img: marine_balast_water,
        alt: 'marine balast water'
    },
    {
        title: 'PAINTS',
        img: paints,
        alt: 'Paints'
    }
]

const Landing = (props) => {

    return(
        <Fragment>
            <section className="categorySection">
                <Container fluid>
                    <Carousel autoplay effect="fade" speed={250}>
                        {
                            Frontcategories.map((cat, index) => {
                                return(
                                    <div className="grad" key={index}>
                                        <div style={{height: 550, width:'100%', background: `url(${cat.img}) no-repeat`,backgroundSize: '100% 100%' }}>
                                            <h2 className="mb-4 categoryTitle"> {cat.title}</h2>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </Carousel>
                </Container>
            </section>
            <section className="company-section">
                <Carousel autoplay effect="fade">
                    <div>
                        <div style={carousel1Style}>
                            <div className="overlay"></div>
                            <Container>
                                <Row className="no-gutters align-items-center justify-content-front" style={{ height: '700px'}}>
                                    <Col md={{ span: 6, offset: 6 }} style={{ position: 'relative'}}>
                                            <h1 className="carousel_company_name mb-4">MH MARINE SERVICE</h1>
                                            <h3 className="subheading">
                                                We, <b>"MH MARINE"</b> is a well established in the Indian
                                                Marine Industry. Our products are
                                                known for its Commendable Quality, Competitive Pricing and Comprehensive
                                                Range.
                                            </h3>
                                            <Button variant="primary">Request a Quote</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Carousel>
            </section>
            <section className="products_section">
                <Container>
                    <Row className="justify-content-center mb-5 pb-2">
                        <Col md={8} className="text-align-center ">
                            <h2 className="mb-4"> Our Products</h2>
                        </Col>
                    </Row>
                    <List
                        grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                        }}
                        dataSource={fetchCategories("categories")}
                        renderItem={item => (
                        <List.Item>
                            <Card border='light'>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                <Button href={item.url} variant="primary">{item.buttonText}</Button>
                                </Card.Body>
                            </Card>
                        </List.Item>
                        )}
                    />
                </Container>
                <div>
                    <img src = "" alt = "Defailt" />
                </div>
            </section>
            
        </Fragment>
    )
}

export default Landing;

/*

                        */
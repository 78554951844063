import { Fragment, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HomeOutlined, MailOutlined, PhoneOutlined, GlobalOutlined } from "@ant-design/icons";
import contactImage from "../../assets/images/contact_us.jpg";

import emailjs from 'emailjs-com';

import "./contact.css";

const ContactPage = () => {

    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [emailResponse, setEmailResponse] = useState('');

    const subMitContactForm = () => {
        if(contactName === ""){
            setIsError(true)
            setErrorMessage("Please Specify Your Name!")
        }
        else if(contactEmail === ""){
            setIsError(true)
            setErrorMessage("Please Specify Your Email Address!")
        }
        else if(contactSubject === ""){
            setIsError(true)
            setErrorMessage("Please Specify Subject!")
        }
        else if(contactMessage === ""){
            setIsError(true)
            setErrorMessage("Please Specify Message!")
        }
        else{
            setIsError(false)
            setErrorMessage("");
        }

        const templateParams = {
            contact_name: contactName,
            contact_email: contactEmail,
            contact_subject: contactSubject,
            message: contactMessage
        }
        
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((res) => {
                setEmailResponse("Sucessfully Send Message!, We will reach to you at the earliest")     
            })
            .catch((err) => {
                setEmailResponse("We are sorry for incovinience!, Please Try Again")
            })
         
    }
    return (
        <Fragment>
            <div className="contact-bg">
                <Container>
                    <Row>
                        <Col>
                            <div className="contacttitle">
                                <h2>Contact Us</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="contact">
                <dir className="contacts-bg">
                    <Container>
                        <Row>
                            <Col>
                                <div className="constacts-us">
                                    <h3><strong className="cur">Reach Us!</strong></h3>
                                    <form>
                                        <div className="form-group">
                                            <input type="text" name="contact_name" value={contactName} className="form-control" placeholder="Your Name" onChange={(e) => setContactName(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="contact_email" value={contactEmail} className="form-control" placeholder="Your Email" onChange={(e) => setContactEmail(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="contact_subject" value={contactSubject} className="form-control" placeholder="Subject" onChange={(e) => setContactSubject(e.target.value)}/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" id="message" value={contactMessage} cols="30" rows="7" className="form-control" placeholder="Message" onChange={(e) => setContactMessage(e.target.value)}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input type="button" value="Send Message" className="btn btn-primary py-3 px-5" onClick = {subMitContactForm} />
                                        </div>
                                    </form>
                                    {
                                        isError === true ?
                                        (
                                            <span className="text-danger">{errorMessage}</span>
                                        ) : (
                                            <span className="text-info">{emailResponse}</span>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col>
                                    <img src={contactImage} alt = "Contact Us" width="100%" />
                            </Col>
                        </Row>
                    </Container>
                </dir>
            </div>
            <section className="contact-section">
                <Container>
                    <Row className="d-flex mb-5 contact-info">
                        <Col>
                            <h2> Contact </h2>
                        </Col>
                        <div className="w-100"></div>
                        <Col >
                            <div className="bg-light d-flex align-self-stretch box p-2">
                                <p><span><HomeOutlined /></span> Swapnapurti Bhawan, Plot No. 2A, Near Bank of Baroda, Andheri(E), Mumbai-69(India)</p>
                            </div>
                            <div className="bg-light d-flex align-self-stretch box p-2">
                                <p><span><PhoneOutlined /></span> <a href="tel://9004249383">+91 9004249383</a></p>
                            </div>
                            <div className="bg-light d-flex align-self-stretch box p-2">
                                <p><span><MailOutlined /></span> <a href="mailto:info@mhmarine.in">info@mhmarine.in</a></p>
                            </div>
                            <div className="bg-light d-flex align-self-stretch box p-2">
                                <p><span><GlobalOutlined /></span> <a href="/">www.mhmarine.in</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default ContactPage;